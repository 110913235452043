import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';

class Contacts extends Component {
  render() {
    return (
      <div style={{ color: 'white' }}>
        <div className="container">
          <h1 style={{ color: 'white' }}>Користувацька угода</h1>
          <p>
            <b>1. Загальні положення</b>
          </p>
          <p>
            1.1. Ця Користувацька угода (надалі — угода) регламентує правовідносини між сайтом
            volovod.com (надалі — Сайт) і фізичною особою (надалі — Користувач) під час використання
            інформації на сайті http://www.volovod.com/, а також надсилання поштових повідомлень за
            допомогою Поштових сервісів (електронної пошти).
          </p>
          <p>
            1.2. Цю Угода може бути змінено Адміністрацією без жодного спеціального повідомлення
            Користувача. Нова редакція Угоди набуває чинності з моменту її розміщення на порталі.
          </p>
          <p>
            1.3. Правовідносини, що виникають між Користувачем та Адміністрацією у зв'язку з
            виконанням цієї Угоди, регулюються законодавством України.
          </p>
          <p>
            1.4. У разі потреби повернення клієнт може звернутись із проханням про повернення коштів
            впродовж 14 днів, у рази якщо послуга не була надана або була надана не належним чином.
          </p>
          <br />
          <p>
            <b>2. Взаємодія</b>
          </p>
          <p>2.1. Сайт надає Користувачам інтернет-сервіси</p>
          <p>
            2.2. Для того, щоб користуватися Сервісами Сайту, Користувач має пройти наступну
            процедуру реєстрації:
          </p>
          <p>2.2.1. Заповнити реєстраційну форму;</p>
          <p>
            2.2.2. Виразити згоду з умовами цієї Угоди. При цьому, Адміністрація жодним чином не
            здійснює перевірку інформації, що надається Користувачем, на предмет дійсності і не несе
            відповідальності перед будь-якими третіми особами за точність і достовірність такої
            інформації; не розцінює інформацію, що надається Користувачем, як персональні дані, що
            підлягають спеціальному захисту згідно із Законом України «Про захист персональних
            даних»;
          </p>
          <p>
            2.2.3. Після завершення процесу реєстрації Користувач вводить логін облікового запису та
            пароль для доступу до Сервісів Сайту. Користувач персонально несе відповідальність за
            безпеку свого логіну та паролю, а також повну відповідальність за всі дії, які будуть
            здійснені Користувачем у процесі використання Сервісів Сайту;
          </p>
          <p>
            2.2.4. Користувач погоджується з тим, що Адміністрація має право збирати та зберігати
            реєстраційні дані Користувача (у разі отримання таких), у рамках Сервісів з метою
            виконання положень цієї Угоди.
          </p>
          <p>
            2.3. Усі питання надання прав доступу до мережі Інтернет, купівлі та налагодження для
            цього відповідного обладнання та програмного забезпечення вирішуються Користувачем
            самостійно та не підпадають під дію цієї Угоди.
          </p>
          <p>
            2.4. Пароль Користувача від Сервісів Сайту volovod.com може бути відновлений
            Адміністрацією тільки в разі точного, правильного та повного зазначення інформації,
            вказаної при первинній реєстрації Користувачем облікового запису.
          </p>
          <p>
            2.5. Адміністрація не здійснює жодного контролю та не несе відповідальності за зміст
            листування Користувачів, за винятком випадків, передбачених законодавством України.
          </p>
          <br />
          <p>
            <b>3. Сервіси (Розміщення Матеріалів)</b>
          </p>
          <p>
            3.1. Користувач розміщує свої Матеріали на Сайті в межах даного ресурсу без виплати
            будь-якої винагороди.
          </p>
          <p>
            3.2. Користувач усвідомлює та погоджується, що електронна пошта може містити службові
            повідомлення Адміністрації, автоматичні повідомлення та новинні розсилки, та що такі
            повідомлення є необхідною умовою її використання.
          </p>
          <p>
            3.3. Користувач погоджується з тим, що Адміністрація має право розміщувати на сторінках,
            що містять Матеріали Користувача, рекламні банери й об'яви, модифікувати Матеріали з
            метою розміщення реклами.
          </p>
          <p>
            3.4. Відповідальність за несанкціоноване копіювання й використання Матеріалів несуть усі
            та будь-які юридичні й фізичні особи, які неправомірно використали Матеріали, розміщені
            на Сайті.
          </p>
          <p>
            3.5. Доки не встановлено інше, усі майнові й особисті немайнові права на Матеріали
            належать Користувачу, який їх розмістив. Користувача попереджено про встановлену чинним
            законодавством України відповідальність за неправомірне використання й розміщення чужих
            матеріалів (творів). У разі якщо буде встановлено, що Користувач, який розмістив
            Матеріали, не є їхнім законним правовласником, ці Матеріали буде вилучено з вільного
            доступу за першою вимогою законного правовласника.
          </p>
          <p>
            3.6. При розміщенні Матеріалу Адміністрація вказує псевдонім (нікнейм) Користувача,
            указаний Користувачем під час надання Матеріалу.
          </p>
          <p>
            3.7. Користувач розуміє й приймає, що за всю інформацію, дані, текст, повідомлення й
            інші матеріали, розміщені для спільного доступу або передані в приватному порядку,
            відповідає та особа, яка виконала це розміщення.
          </p>
          <br />
          <p>
            <b>4. Припинення дії облікового запису й видалення Матеріалів Користувача</b>
          </p>
          <p>
            4.1. Користувач погоджується з тим, що Адміністрація лишає за собою право припинити дію
            облікового запису Користувача та/або заблокувати Користувачеві доступ до Сайту у
            будь-який час без попереднього повідомлення Користувача та без вказання причини у таких
            випадках:
          </p>
          <p>4.1.1 Порушення положень цієї Угоди, її частин і додатків;</p>
          <p>
            4.1.2 За відповідним запитом органів державної влади, згідно з чинним законодавством;
          </p>
          <p>
            4.1.3 Тривале невикористання (від 6 календарних місяців поспіль) облікового запису для
            доступу до сайту;
          </p>
          <p>
            4.1.4 В інших випадках, якщо Адміністрація вважатиме таке видалення необхідним для
            нормальної роботи Сайту.
          </p>
          <p>
            4.2. Користувач має право у будь-який момент відмовитися від використання свого
            облікового запису в пошті та видалити свій обліковий запис. Дані, розміщені на сайті, у
            таких випадках знищуються без можливості відновлення автоматично.
          </p>
          <p>
            4.3. Припинення дії облікового запису користувача передбачає видалення доступу
            користувача до персональної частини сайту;
          </p>
          <p>
            4.4. Адміністрація лишає за собою право на свій власний розсуд змінювати (модерувати)
            або видаляти будь-яку інформацію, розміщену Користувачем на Сайті, з попереднім
            повідомленням або без такого, без відповідальності за будь-яку шкоду, що може бути
            завдана Користувачеві такою дією.
          </p>
          <br />
          <p>
            <b>5. Права й обов'язки</b>
          </p>
          <p>
            5.1. Адміністрація намагається забезпечувати безперебійну роботу Сайту, однак не несе
            відповідальності за повну або часткову втрату Матеріалів, розміщуваних Користувачем, а
            також за недостатню якість або швидкість надання Сервісів.
          </p>
          <p>
            5.2. Користувач має право розміщувати через Сайт об'єкти інтелектуальної власності,
            право на використання яких належать йому на законних підставах.
          </p>
          <p>
            5.3. Адміністрація має право відмовити Користувачеві в розміщенні Матеріалів, текстових
            повідомлень, а також видалити Матеріали на свій розсуд, якщо їхній зміст суперечить
            вимогам цієї Угоди.
          </p>
          <p>
            5.4. Користувач погоджується з тим, що він несе повну відповідальність за зміст своїх
            Матеріалів, які він розміщує на Сайті. Адміністрація не несе відповідальності за зміст
            Матеріалів і за їхню відповідність вимогам законодавства, за порушення авторських прав,
            несанкціоноване використання знаків для товарів і послуг (торговельних марок),
            найменувань фірм і їхніх логотипів, а також за можливі порушення прав третіх осіб у
            зв'язку з розміщенням Матеріалів на Сайті. У разі надходження претензій від третіх осіб,
            пов'язаних з розміщенням Матеріалів, Користувач самостійно й за свій рахунок врегулює
            вказані претензії.
          </p>
          <p>
            5.5. Автор зазначених у цьому пункті матеріалів зберігає за собою усі майнові й особисті
            немайнові авторські права згідно з законодавством України і міжнародними угодами.
          </p>
          <p>
            5.6. Адміністрація не гарантує, що Сервіси Сайту будуть відповідати вимогам Користувача;
            будуть надаватися безперервно, швидко, надійно та без помилок, а результати, які можуть
            бути отримані Користувачем, будуть точними та надійними.
          </p>
          <p>
            5.7. Будь-які матеріали, отримані Користувачем з використанням Сервісів Сайту Користувач
            використовує на власний страх і ризик. Користувач самостійно несе відповідальність за
            будь-які збитки, які можуть бути завдані комп'ютеру та/або даним в результаті
            завантаження та використання цих матеріалів.
          </p>
          <p>
            5.8. Адміністрація зобов'язується повідомити Користувача про претензії третіх осіб на
            розміщені Користувачем Матеріали. Користувач зобов'язується або надати Адміністрації
            права на використання Матеріалу, або видалити Матеріал.
          </p>
          <p>
            5.9. Адміністрація має право за першою вимогою відповідного вповноваженого
            (правоохоронного) органа, але у відповідності до чинного законодавства, надати такому
            державному органу наявну інформацію про Користувача, не виключаючи персональних даних.
          </p>
          <p>
            5.10. Користувач визнає, що Адміністрація може встановлювати обмеження щодо використання
            Сервісів.
          </p>
          <p>5.11. Адміністрація не несе відповідальності:</p>
          <ul>
            <li>за дії Користувача на Сайті;</li>
            <li>
              за збої, що виникають у телекомунікаційних та/або енергетичних мережах, дію шкідливих
              програм, а також за недобросовісні дії третіх осіб;
            </li>
            <li>
              за зміст та законність Інформації, яку використовує/отримує Користувач в рамках
              Сервісу, а також за її характер;
            </li>
            <li>
              за шкоду/упущену вигоду, заподіяну Користувачеві в результаті використання або
              неможливості використання того чи іншого сервісу;
            </li>
            <li>
              за будь-які пошкодження програмного та/або апаратного комплексу Користувача, що
              виникли внаслідок користування Сервісом.
            </li>
          </ul>
          <br />
          <p>
            <b>6. Загальна інформація</b>
          </p>
          <p>6.1. Ця Угода є юридично зобов'язувальною і для Адміністрації, і для Користувача.</p>
          <p>
            6.2. Користувач має право відмовитися від прийняття змін і доповнень до Угоди, які
            робить Адміністрація, що означає відмову Користувача від Сервісів Сайту.
          </p>
          <p>
            6.3. Усі можливі суперечки, що виникають у зв'язку з або з приводу виконання цієї Угоди,
            вирішуються згідно з нормами чинного законодавства України.
          </p>
          <p>
            6.4. Ніщо в Угоді не може тлумачитись як встановлення між Користувачем и Адміністрацією
            агентських відносин, відносин товариства, відносин зі спільної діяльності, відносин
            особистого найму або будь-яких інших правовідносин, прямо не передбачених цією Угодою.
          </p>
          <p>
            6.5. Користувач, який вважає, що його права й інтереси порушені через дії Адміністрації
            або третіх осіб у зв'язку з розміщенням на Сайті будь-якого Матеріалу, спрямовує
            претензію до службу підтримки.
          </p>
          <p>
            6.6. У разі прийняття нормативно-правових актів органами влади України, які зачіпали би
            цілком або частково функціонування Сайту або Сервісів, Адміністрація зберігає за собою
            право внесення будь-яких змін у функціонування Сервісів, спрямованих на приведення
            діяльності Сайту до відповідності новим нормам.
          </p>
          <p>
            6.7. Юридічна адреса - Україна, 04116, місто Київ, вул Гаврілішіна Богдана 3, квартира
            16
          </p>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Contacts));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
